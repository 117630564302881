import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastImage from '../images/projects/payroc/mast.jpg'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const Payroc = ({ data, path }) => {

    return (
        <Project
            title="Payroc"
            image={mastImage}
            pathname={path}
        >
            <Content header="About">
                <p>
                Payroc is a global payment processing company that offers comprehensive 
                solutions for businesses to accept payments across various channels, 
                including in-person, online, and mobile transactions.
                </p>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('pr')} />
                </div>
                <div className="project-page-btns">
                    <ProjectLinks projectData={projects.get('pr')} />
                </div>
                <FeatureCreator projectData={projects.get('pr')} />
             </Content>
        </Project>
    )
}


Payroc.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default Payroc
